import Vue from 'vue';
import Vuex, { MutationTree } from 'vuex';

import accountsStore from './modules/AccountsStore';

import projectsStore from '@/store/modules/ProjectsStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accountsStore,
    projectsStore,
  },
});

export type VueMutationTree<S = unknown> = MutationTree<S> & { _vm: Vue };
