export class RouteNames {
  static readonly DASHBOARD = 'Dashboard';

  static readonly ACCOUNTS = 'Accounts';

  static readonly ACCOUNTS_SEARCH = 'Accounts Search';

  static readonly ACCOUNT_DETAILS = 'Account Details';

  static readonly PROJECTS = 'Projects';

  static readonly PROJECTS_SEARCH = 'Projects Search';

  static readonly PROJECT_DETAILS = 'Project Details';
}
