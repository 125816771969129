













import Vue from 'vue';

export default Vue.extend({
  name: 'ProjectsFilter',
  props: {
    searchText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      text: this.searchText as null | string,
    };
  },
  methods: {
    changed() {
      this.$emit('changed', this.text);
    },
  },
});
