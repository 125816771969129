import Vue from 'vue';
import Router from 'vue-router';

import { RouteNames } from './models/config/route-name';
import { authService } from './services/auth.service';

import AccountDetails from '@/views/accounts/AccountDetails.vue';
import Accounts from '@/views/accounts/Accounts.vue';
import AccountsSearch from '@/views/accounts/AccountsSearch.vue';
import ProjectDetails from '@/views/projects/ProjectDetails.vue';
import Projects from '@/views/projects/Projects.vue';
import ProjectsSearch from '@/views/projects/ProjectsSearch.vue';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: RouteNames.DASHBOARD,
      redirect: {
        name: RouteNames.ACCOUNTS_SEARCH,
      },
    },
    {
      path: '/accounts',
      name: RouteNames.ACCOUNTS,
      component: Accounts,
      redirect: {
        name: RouteNames.ACCOUNTS_SEARCH,
      },
      children: [
        {
          path: '/',
          name: RouteNames.ACCOUNTS_SEARCH,
          component: AccountsSearch,
        },
        {
          path: ':accountId',
          name: RouteNames.ACCOUNT_DETAILS,
          component: AccountDetails,
        },
      ],
    },
    {
      path: '/projects',
      name: RouteNames.PROJECTS,
      component: Projects,
      redirect: {
        name: RouteNames.PROJECTS_SEARCH,
      },
      children: [
        {
          path: '/',
          name: RouteNames.PROJECTS_SEARCH,
          component: ProjectsSearch,
        },
        {
          path: ':projectId',
          name: RouteNames.PROJECT_DETAILS,
          component: ProjectDetails,
        },
      ],
    },
    {
      path: '/*',
      redirect: {
        name: RouteNames.DASHBOARD,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  authService.getUser().then(async (user) => {
    if (user != null && !user.expired) {
      next();
    } else {
      // Not yet signed in, or not authorised, so navigate to login
      await authService.login();
    }
  });
});

export default router;
