import { AxiosResponse } from 'axios';
import { Module } from 'vuex';

import { ProjectSearchResult } from '@/models/projects/project-search-result';
import { ProjectSearchRequest } from '@/models/search/accounts/project-search-request';
import { IPage } from '@/models/search/i-page';
import { projectService } from '@/services/project.service';
import { ProjectActionTypes } from '@/store/project-action-types';
import { ProjectMutationTypes } from '@/store/project-mutation-types';

type ProjectStoreState = {
  projects: IPage<ProjectSearchResult> | null;
  isProjectsLoading: boolean;
};

const projectsStore: Module<ProjectStoreState, unknown> = {
  state: {
    projects: null,
    isProjectsLoading: true,
  },
  mutations: {
    [ProjectMutationTypes.FETCH_PROJECTS](
      state,
      projects: IPage<ProjectSearchResult>,
    ) {
      state.projects = projects;
      state.isProjectsLoading = false;
    },
    [ProjectMutationTypes.ADD_PROJECT](state, project: ProjectSearchResult) {
      if (state.projects) {
        state.projects.data.push(project);
      } else {
        state.projects = {
          data: [project],
        } as IPage<ProjectSearchResult>;
      }
    },
    [ProjectMutationTypes.PROJECTS_LOADING](state, loading: boolean) {
      state.isProjectsLoading = loading;
    },
  },
  actions: {
    [ProjectActionTypes.FETCH_PROJECTS](
      context,
      request: ProjectSearchRequest,
    ): Promise<AxiosResponse<IPage<ProjectSearchResult>>> {
      return new Promise((resolve, reject) => {
        context.commit(ProjectMutationTypes.PROJECTS_LOADING, true);
        projectService.search(request).then(
          (response) => {
            context.commit(ProjectMutationTypes.FETCH_PROJECTS, response.data);
            resolve(response);
          },
          (error) => {
            context.commit(ProjectMutationTypes.PROJECTS_LOADING, false);
            reject(error);
          },
        );
      });
    },
  },
  getters: {
    getProject: (state) => (id: string) => state.projects?.data.find(
      (project) => project.projectId === id,
    ),
  },
};
export default projectsStore;
