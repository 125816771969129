


















import Vue from 'vue';

import ProjectsFilter from '@/components/projects/ProjectsFilter.vue';
import ProjectsTable from '@/components/projects/ProjectsTable.vue';
import { ProjectSearchRequest } from '@/models/search/accounts/project-search-request';
import { PagingRequest } from '@/models/search/paging-request';
import { SortRequest } from '@/models/search/sort-request';
import { ProjectActionTypes } from '@/store/project-action-types';

export default Vue.extend({
  name: 'ProjectsSearch',
  components: {
    ProjectsFilter,
    ProjectsTable,
  },
  data() {
    return {
      searchText: null as string | null,
      sortRequest: {
        sortProperty: 'projectId',
        descending: true,
      } as SortRequest,
      pagingRequest: {
        pageSize: 50,
        pageNumber: 0,
      },
    };
  },
  created() {
    this.search();
  },
  methods: {
    search() {
      const request = {
        searchValue: this.searchText,
        ...this.sortRequest,
        ...this.pagingRequest,
      } as ProjectSearchRequest;

      this.$store.dispatch(ProjectActionTypes.FETCH_PROJECTS, request);
    },
    changedSearchText(text: string) {
      this.searchText = text;
      this.resetPageNumber();
      this.search();
    },
    changedSort(request: SortRequest) {
      this.sortRequest = request;
      this.resetPageNumber();
      this.search();
    },
    changedPaging(request: PagingRequest) {
      this.pagingRequest = request;
      this.search();
    },
    resetPageNumber() {
      this.pagingRequest.pageNumber = 0;
    },
  },
});
